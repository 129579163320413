

export default function PageNotFound() {

  return (
    <div className="App">
        <div className="error_page">
          <img src="/img/404.svg"></img>
          <h1>404 Please Try Again,URL Was Not Found</h1>
        </div>
    </div>
    
  );

}