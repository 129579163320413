import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { API_URL_BASE, X_APP_SECRET, headers } from './../utils'
import { API_URLS } from './../utils'
import { useNavigate, Navigate } from 'react-router-dom'
import { IStateSignup } from '../Model/Interface'
import routes from '../Routes'
import secureLocalStorage from 'react-secure-storage'

export default function ForgotPassword() {

    const [errmsg, setErrormsg] = useState("");
    const navigate = useNavigate();

    const [state, setState] = useState<{ user: { email: string } }>({
        user: { email: '' },
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setState({
            user: {
                ...state.user,
                [event.target.name]: event.target.value,
            },
        });
    };


    const SendMail = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        let email = state.user.email;
        let Preemail = state.user.email;


        let data = { email }

        fetch(`${API_URL_BASE}${API_URLS.FORGOT_PASSWORD_OTP_SENT}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
              },
            body: JSON.stringify(data)
        }).then((result) => {
            result.json().then((resp) => {

                if (resp.response == false) {
                    setErrormsg(resp.message);
                } else {
                    secureLocalStorage.setItem("user-email", JSON.stringify(email));
                    navigate(routes.otp, { state: { Preemail } });

                }
            })
        })
    }


    return (
        <>
            <div className="login_bg">
                <div className="login_content">
                    <div className="login_logo">
                        <Link to={"/"} className="logo"><img src="/img/play_now_logo.png"></img></Link>
                    </div>
                    <form onSubmit={SendMail}>
                        <div className="login_box">
                            <div className="login_box_title">
                                <h3>Forgot Password</h3>
                            </div>
                            <div className="login_box_content">
                                <div className="input_box">
                                    <span style={{ color: "red" }} >{errmsg}</span>
                                    <input type="email" name="email" value={state.user.email} onChange={handleChange} className="input_fild" placeholder="Email"></input>

                                    <input type="submit" className="submit_btn" value="Continue"></input>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
