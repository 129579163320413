import { User } from "./Helper";

var user = User();

const token = user?.data.token


export const API_URLS = {
  SIGN_UP: '/user/register',
  SIGN_IN: '/login',
  LOG_OUT: '/user/logout',
  
  FORGOT_PASSWORD: '/emailverification',
  FORGOT_PASSWORD_OTP_SENT: '/user/forgot/password/otp',
  FORGOT_PASSWORD_OTP_VERIFY: '/user/forgot/otp/verify',
  CHANGE_PASSWORD: '/user/change/forgot/password',
  USER_PROFILE_LIST: '/user-profile/list',
  USER_PROFILE_SELECT: '/user-profile/select',
  USER_PROFILE_CREATE: '/user-profile/create',
  USER_PROFILE_UPDATE: '/user-profile/update',
  USER_PROFILE_DETAIL: '/user-profile/detail',
  USER_PROFILE_REMOVE: '/user-profile/remove',
  USER_WATCHLIST_LIST: '/user-watchlist-item/list',
  MOVIE_DETIALS: '/movie/detail',
  MORE_LIKE_THIS_MOVIES: '/movie/more-like-this',
  NEW_RELEASE_MOVIES: '/movie/new-release',
  TRENDING_MOVIES: '/movie/trending',
  HOME_SLIDER: '/home/slider',
  TVSHOW_DETAILS: '/tvshow/detail',
  TVSHOW_MORE_LIKE_THIS: '/tvshow/more-like-this',
  TVSHOW_NEW_RELEASE: '/tvshow/new-release',
  TVSHOW_TRENDING: '/tvshow/trending',
  TVSHOW_SEASON_EPISODE: '/tvshow/episode/list',
  MATURITY_LEVEL: '/maturity-level',
  USER_ITEM_WATCHLIST: '/user/watchlist/item/list',
  USER_FAVOURITE_LIST: '/user/favourite/list',
  GENRES_WISE_MOVIES: '/movie/genre-wise',
  GENRES_WISE_TVSHOW: '/tvshow/genre-wise',
  GENRES_LIST: '/genres',
  WATCHLIST_ADD_REMOVE: '/user/watchlist/item/addremove',
  FAVORITE_ADD_REMOVE: '/user/favourite/addremove',
  USER_CHANGE_PASSWORD:'/user/change-password',
  SEARCHDATA:'/search/data',
  PLAY_VIDEO:'/playcontent',
  CONTINUE_WATCHING_LIST:'/continue-watching/list',
  CONTINUE_WATCHING_ADD:'/continue-watching/add',
  TOP_SEARCH_LIST:'/topsearch/list',
  TOP_SEARCH_ADD:'/topsearch/store',
  ACTIVE_ACCOUNT:'/active/account',
  RESEND_CODE:'/activation-code/resend',
  ADVERTISEMENT_URL:'/advertisement/url',
  FEATURES_LIST:'/features/list',
  SUBSCRIPTION_LIST:'/subscriptions/list',
  SUBSCRIPTION_CHECKOUT:'/checkout',
  USER_ACTIVE_PLAN:'/user/active/subscription',
  USER_BILLING_DETAILS:'/subscription/billing/details',
  USER_PLAN_CANCEL:'/subscription/cancel',
  USER_DATA_CAPTURE:'/user/data/capture',
  CLOSE_ACCOUNT_REASON_LIST:'/user/close/account/reason/list',
  CLOSE_ACCOUNT:'/user/close/account',
  USER_LOCATION:'/features/location',
  ITEM_PURCHASE:'/item/purchase/checkout',
  ITEM_PAYMENT_SUCCESS:'/item/payment/success',

  };
  
  export const AUTH_LOCAL_STORAGE_KEY = 'authToken';

  export const API_URL_BASE = "https://admin-playnow.clonegranny.com/api";
  export const X_APP_SECRET = "vEU9Hu6AJa37sU4dxOZlqD6pjKurDvptNkrQu0OnHs0fiD71IK6BN7jY";
  

  export const STRIPE_TEST_KEY       = "pk_test_51CS2BxFfQn6jQO1QQuG47ZyEkCtAHkGMh4kJHRi5XrGCOyVlqttXdDziFo2BJXZWkOHfPqOflpmCC1cyts63OerZ00okXWSZwc";
  
  
  export const headers = {
    Accept: 'application/json',
    'Content-type': 'application/json',
    'X-APP-SECRET': "vEU9Hu6AJa37sU4dxOZlqD6pjKurDvptNkrQu0OnHs0fiD71IK6BN7jY",
    Authorization : 'Bearer ' + token,
  };
  
  
  // export const API_URL_BASE = 'https://admin-playnow.clonegranny.com/api'; live url
  // export const API_URL_BASE = 'https://playnow.clonegranny.com/api';  devlopment url

  // http://playnow-2023.dv/public/api   local Keyur Pc URl
  
  


  