import React, { useState, useEffect, useRef, FormEvent, KeyboardEvent } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import routes from '../Routes';
import { API_URLS, API_URL_BASE, X_APP_SECRET, headers } from '../utils';
import secureLocalStorage from 'react-secure-storage';


const Active: React.FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (secureLocalStorage.getItem('playnow-user')) {
      navigate(routes.home);
    }
  }, [navigate]);

  function showSuccessNotification(message: any) {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  const location = useLocation();
  const { Preemail } = location.state || {};

  const [activationKey, setActivationKey] = useState<string[]>(Array.from({ length: 6 }, () => ''));

  const [errmsg, setErrormsg] = useState<string>('');

  const [state, setState] = useState<{ user: { email: string, activation_key: string } }>({
    user: { email: Preemail && Preemail?Preemail:"", activation_key: '' },
  });
  const [isButtonDisabled, setButtonDisabled] = useState(true);



  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setState({
      user: {
        ...state.user,
        [event.target.name]: event.target.value,
      },
    });
  };

  const handleChangeActivationKey = (event: React.ChangeEvent<HTMLInputElement>, index: number): void => {
    const value = event.target.value;

    setActivationKey((prevActivationKey) => {
      const newActivationKey = [...prevActivationKey];
      newActivationKey[index] = value;
      return newActivationKey;
    });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let email = state.user.email;
    let activation_key = activationKey.join('');
    let data = { email, activation_key }


    fetch(`${API_URL_BASE}${API_URLS.ACTIVE_ACCOUNT}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
        'X-APP-SECRET': X_APP_SECRET,
      },
      body: JSON.stringify(data)
    }).then((result) => {
      result.json().then((resp) => {

        if (resp.response == false) {
          setErrormsg(resp.message);
        } else {
          navigate(routes.signin)
        }
      })
    })
  };
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          setButtonDisabled(false)
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const ResendCode = () => {
    // Disable the button
    setSeconds(60);
    setButtonDisabled(true);

    let email = state.user.email;
    let data = { email }
    fetch(`${API_URL_BASE}${API_URLS.RESEND_CODE}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
        'X-APP-SECRET': X_APP_SECRET,
      },
      body: JSON.stringify(data)
    }).then((result) => {
      result.json().then((resp) => {
        if (resp.response == false) {
          setErrormsg(resp.message);
        } else {
          showSuccessNotification(resp.message)
        }
      })
    })
  }


  // State for input refs
  const inputRefs = initializeInputRefs();

  // Function to handle changes in the activation key inputs
  const handleActivationKeyChange = (e: React.ChangeEvent<HTMLInputElement>, index: number): void => {
    const value = e.target.value;


    if (value === '' && index > 0) {
      // If backspace is pressed and the input is not the first one
      setErrormsg('');
      inputRefs[index - 1].current?.focus();
    } else if (/^\d$/.test(value)) {
      // Allow only single digits
      setErrormsg('');

      // Move focus to the next input field
      const nextInputRef = inputRefs[index + 1];
      if (nextInputRef && nextInputRef.current && value !== '') {
        nextInputRef.current.focus();
      }
    } else {
      setErrormsg('Please enter a single digit.');
    }
  };
  return (
    <>
      <ToastContainer />
      <div className="login_bg">
        <div className="login_content">
          <div className="login_logo">
            <Link to={'/'} className="logo">
              <img src="/img/play_now_logo.png" alt="logo" />
            </Link>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="login_box">
              <div className="login_box_title">
                <h3>Enter Activation Code</h3>
              </div>
              <div className="login_box_content active_box">
                <div className="input_box">
                  <span style={{ color: 'red' }}>{errmsg}</span>
                  <input type="email" name="email"  value={state.user.email}  onChange={handleChangeEmail}     className="input_fild" placeholder="Email"></input>
                  <div className="otp_screen">
                    {inputRefs.map((ref, index) => (
                      <input
                        key={index}
                        type="text"
                        name={`activation_key_${index}`}
                        className="input_fild"
                        placeholder="0"
                        maxLength={1}
                        ref={ref}
                        onChange={(e) => {
                          handleActivationKeyChange(e, index);
                          handleChangeActivationKey(e, index);
                        }}
                      />
                    ))}
                  </div>
                  
                  {/* <Link to={''} onClick={ResendCode} className="resend_code_txt">
                    Resend code
                  </Link> */}

                  {seconds > 0 || minutes > 0 ? (
                    <div className='remain_text'>
                      <span className="resend_code_txt p-0">
                        Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                        {seconds < 10 ? `0${seconds}` : seconds}
                      </span>
                    </div>
                  ) : (
                    <div className='resend_btn'>
                      <span className="resend_code_txt p-0">Didn't receive code?</span>
                      <button className="resend__action_btn" onClick={ResendCode} disabled={isButtonDisabled}>
                        Resend
                      </button>
                    </div>
                  )}


                  <input type="submit" className="submit_btn" value="Enter" />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Active;

function initializeInputRefs() {
  return Array.from({ length: 6 }, () => useRef<HTMLInputElement | null>(null));
}
