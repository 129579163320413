import React, { useState } from 'react'
import Header from './Layout/Header'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal';
import Genres from './Layout/MovieGenres';
import Footer from './Layout/Footer';

export default function SelectGenres() {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div>
            <Header />
            <Container fluid className='cust_container inner_section'>
                <Genres />
                <div className="account_setting_title">
                    <h3>TV Sci-Fi & Horror</h3>
                </div>
                <div className="view_all_section">
                    <div className="slick-slider episode_details">
                        <div className='item_box watchlist_box'>
                            <div onClick={handleShow}>
                                <img className="img" src="/img/img-4.png" />
                            </div>
                            <div className="item_img_details">
                                <div className="item_img_header">
                                    <h5>Friends</h5>
                                    <ul>
                                        <li><Link to={""}><img src="img/play-icon.png" alt=""></img></Link></li>
                                        <li><Link to={""}><img src="img/add-icon.png" alt=""></img></Link></li>
                                        <li><Link to={""}><img src="img/like-icon.png" alt=""></img></Link></li>
                                    </ul>
                                </div>
                                <div className="item_img_number">
                                    <h3>U/A 16+</h3>
                                    <h4>2022 &nbsp;|&nbsp; Commedy</h4>
                                </div>
                                <div className="item_description">
                                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an</p>
                                </div>
                            </div>
                        </div>


                        <div className='item_box watchlist_box'>
                            <div onClick={handleShow}>
                                <img className="img" src="/img/img-7.png" />
                            </div>
                            <div className="item_img_details">
                                <div className="item_img_header">
                                    <h5>Friends</h5>
                                    <ul>
                                        <li><Link to={""}><img src="img/play-icon.png" alt=""></img></Link></li>
                                        <li><Link to={""}><img src="img/add-icon.png" alt=""></img></Link></li>
                                        <li><Link to={""}><img src="img/like-icon.png" alt=""></img></Link></li>
                                    </ul>
                                </div>
                                <div className="item_img_number">
                                    <h3>U/A 16+</h3>
                                    <h4>2022 &nbsp;|&nbsp; Commedy</h4>
                                </div>
                                <div className="item_description">
                                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an</p>
                                </div>
                            </div>
                        </div>
                        <div className='item_box watchlist_box'>
                            <div onClick={handleShow}>
                                <img className="img" src="/img/img-6.png" />
                            </div>
                            <div className="item_img_details">
                                <div className="item_img_header">
                                    <h5>Friends</h5>
                                    <ul>
                                        <li><Link to={""}><img src="img/play-icon.png" alt=""></img></Link></li>
                                        <li><Link to={""}><img src="img/add-icon.png" alt=""></img></Link></li>
                                        <li><Link to={""}><img src="img/like-icon.png" alt=""></img></Link></li>
                                    </ul>
                                </div>
                                <div className="item_img_number">
                                    <h3>U/A 16+</h3>
                                    <h4>2022 &nbsp;|&nbsp; Commedy</h4>
                                </div>
                                <div className="item_description">
                                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an</p>
                                </div>
                            </div>
                        </div>
                        <div className='item_box watchlist_box'>
                            <div onClick={handleShow}>
                                <img className="img" src="/img/img-5.png" />
                            </div>
                            <div className="item_img_details">
                                <div className="item_img_header">
                                    <h5>Friends</h5>
                                    <ul>
                                        <li><Link to={""}><img src="img/play-icon.png" alt=""></img></Link></li>
                                        <li><Link to={""}><img src="img/add-icon.png" alt=""></img></Link></li>
                                        <li><Link to={""}><img src="img/like-icon.png" alt=""></img></Link></li>
                                    </ul>
                                </div>
                                <div className="item_img_number">
                                    <h3>U/A 16+</h3>
                                    <h4>2022 &nbsp;|&nbsp; Commedy</h4>
                                </div>
                                <div className="item_description">
                                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an</p>
                                </div>
                            </div>
                        </div>
                        <div className='item_box watchlist_box'>
                            <div onClick={handleShow}>
                                <img className="img" src="/img/img-1.png" />
                            </div>
                            <div className="item_img_details">
                                <div className="item_img_header">
                                    <h5>Friends</h5>
                                    <ul>
                                        <li><Link to={""}><img src="img/play-icon.png" alt=""></img></Link></li>
                                        <li><Link to={""}><img src="img/add-icon.png" alt=""></img></Link></li>
                                        <li><Link to={""}><img src="img/like-icon.png" alt=""></img></Link></li>
                                    </ul>
                                </div>
                                <div className="item_img_number">
                                    <h3>U/A 16+</h3>
                                    <h4>2022 &nbsp;|&nbsp; Commedy</h4>
                                </div>
                                <div className="item_description">
                                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an</p>
                                </div>
                            </div>
                        </div>
                        <div className='item_box watchlist_box'>
                            <div onClick={handleShow}>
                                <img className="img" src="/img/img-2.png" />
                            </div>
                            <div className="item_img_details">
                                <div className="item_img_header">
                                    <h5>Friends</h5>
                                    <ul>
                                        <li><Link to={""}><img src="img/play-icon.png" alt=""></img></Link></li>
                                        <li><Link to={""}><img src="img/add-icon.png" alt=""></img></Link></li>
                                        <li><Link to={""}><img src="img/like-icon.png" alt=""></img></Link></li>
                                    </ul>
                                </div>
                                <div className="item_img_number">
                                    <h3>U/A 16+</h3>
                                    <h4>2022 &nbsp;|&nbsp; Commedy</h4>
                                </div>
                                <div className="item_description">
                                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an</p>
                                </div>
                            </div>
                        </div>
                        <div className='item_box watchlist_box'>
                            <div onClick={handleShow}>
                                <img className="img" src="/img/img-3.png" />
                            </div>
                            <div className="item_img_details">
                                <div className="item_img_header">
                                    <h5>Friends</h5>
                                    <ul>
                                        <li><Link to={""}><img src="img/play-icon.png" alt=""></img></Link></li>
                                        <li><Link to={""}><img src="img/add-icon.png" alt=""></img></Link></li>
                                        <li><Link to={""}><img src="img/like-icon.png" alt=""></img></Link></li>
                                    </ul>
                                </div>
                                <div className="item_img_number">
                                    <h3>U/A 16+</h3>
                                    <h4>2022 &nbsp;|&nbsp; Commedy</h4>
                                </div>
                                <div className="item_description">
                                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an</p>
                                </div>
                            </div>
                        </div>

                        <div className='item_box watchlist_box'>
                            <div onClick={handleShow}>
                                <img className="img" src="/img/img-4.png" />
                            </div>
                            <div className="item_img_details">
                                <div className="item_img_header">
                                    <h5>Friends</h5>
                                    <ul>
                                        <li><Link to={""}><img src="img/play-icon.png" alt=""></img></Link></li>
                                        <li><Link to={""}><img src="img/add-icon.png" alt=""></img></Link></li>
                                        <li><Link to={""}><img src="img/like-icon.png" alt=""></img></Link></li>
                                    </ul>
                                </div>
                                <div className="item_img_number">
                                    <h3>U/A 16+</h3>
                                    <h4>2022 &nbsp;|&nbsp; Commedy</h4>
                                </div>
                                <div className="item_description">
                                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="account_setting_title">
                    <h3>Trending Now</h3>
                </div>
                <div className="view_all_section">
                    <div className="slick-slider episode_details">
                        <div className='item_box watchlist_box'>
                            <div onClick={handleShow}>
                                <img className="img" src="/img/tranding_one.png" />
                            </div>
                            <div className="item_img_details">
                                <div className="item_img_header">
                                    <h5>Friends</h5>
                                    <ul>
                                        <li><Link to={""}><img src="img/play-icon.png" alt=""></img></Link></li>
                                        <li><Link to={""}><img src="img/add-icon.png" alt=""></img></Link></li>
                                        <li><Link to={""}><img src="img/like-icon.png" alt=""></img></Link></li>
                                    </ul>
                                </div>
                                <div className="item_img_number">
                                    <h3>U/A 16+</h3>
                                    <h4>2022 &nbsp;|&nbsp; Commedy</h4>
                                </div>
                                <div className="item_description">
                                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an</p>
                                </div>
                            </div>
                        </div>


                        <div className='item_box watchlist_box'>
                            <div onClick={handleShow}>
                                <img className="img" src="/img/tranding_two.png" />
                            </div>
                            <div className="item_img_details">
                                <div className="item_img_header">
                                    <h5>Friends</h5>
                                    <ul>
                                        <li><Link to={""}><img src="img/play-icon.png" alt=""></img></Link></li>
                                        <li><Link to={""}><img src="img/add-icon.png" alt=""></img></Link></li>
                                        <li><Link to={""}><img src="img/like-icon.png" alt=""></img></Link></li>
                                    </ul>
                                </div>
                                <div className="item_img_number">
                                    <h3>U/A 16+</h3>
                                    <h4>2022 &nbsp;|&nbsp; Commedy</h4>
                                </div>
                                <div className="item_description">
                                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an</p>
                                </div>
                            </div>
                        </div>
                        <div className='item_box watchlist_box'>
                            <div onClick={handleShow}>
                                <img className="img" src="/img/tranding_three.png" />
                            </div>
                            <div className="item_img_details">
                                <div className="item_img_header">
                                    <h5>Friends</h5>
                                    <ul>
                                        <li><Link to={""}><img src="img/play-icon.png" alt=""></img></Link></li>
                                        <li><Link to={""}><img src="img/add-icon.png" alt=""></img></Link></li>
                                        <li><Link to={""}><img src="img/like-icon.png" alt=""></img></Link></li>
                                    </ul>
                                </div>
                                <div className="item_img_number">
                                    <h3>U/A 16+</h3>
                                    <h4>2022 &nbsp;|&nbsp; Commedy</h4>
                                </div>
                                <div className="item_description">
                                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an</p>
                                </div>
                            </div>
                        </div>
                        <div className='item_box watchlist_box'>
                            <div onClick={handleShow}>
                                <img className="img" src="/img/tranding_four.png" />
                            </div>
                            <div className="item_img_details">
                                <div className="item_img_header">
                                    <h5>Friends</h5>
                                    <ul>
                                        <li><Link to={""}><img src="img/play-icon.png" alt=""></img></Link></li>
                                        <li><Link to={""}><img src="img/add-icon.png" alt=""></img></Link></li>
                                        <li><Link to={""}><img src="img/like-icon.png" alt=""></img></Link></li>
                                    </ul>
                                </div>
                                <div className="item_img_number">
                                    <h3>U/A 16+</h3>
                                    <h4>2022 &nbsp;|&nbsp; Commedy</h4>
                                </div>
                                <div className="item_description">
                                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an</p>
                                </div>
                            </div>
                        </div>
                        <div className='item_box watchlist_box'>
                            <div onClick={handleShow}>
                                <img className="img" src="/img/tranding_five.png" />
                            </div>
                            <div className="item_img_details">
                                <div className="item_img_header">
                                    <h5>Friends</h5>
                                    <ul>
                                        <li><Link to={""}><img src="img/play-icon.png" alt=""></img></Link></li>
                                        <li><Link to={""}><img src="img/add-icon.png" alt=""></img></Link></li>
                                        <li><Link to={""}><img src="img/like-icon.png" alt=""></img></Link></li>
                                    </ul>
                                </div>
                                <div className="item_img_number">
                                    <h3>U/A 16+</h3>
                                    <h4>2022 &nbsp;|&nbsp; Commedy</h4>
                                </div>
                                <div className="item_description">
                                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an</p>
                                </div>
                            </div>
                        </div>
                        <div className='item_box watchlist_box'>
                            <div onClick={handleShow}>
                                <img className="img" src="/img/tranding_six.png" />
                            </div>
                            <div className="item_img_details">
                                <div className="item_img_header">
                                    <h5>Friends</h5>
                                    <ul>
                                        <li><Link to={""}><img src="img/play-icon.png" alt=""></img></Link></li>
                                        <li><Link to={""}><img src="img/add-icon.png" alt=""></img></Link></li>
                                        <li><Link to={""}><img src="img/like-icon.png" alt=""></img></Link></li>
                                    </ul>
                                </div>
                                <div className="item_img_number">
                                    <h3>U/A 16+</h3>
                                    <h4>2022 &nbsp;|&nbsp; Commedy</h4>
                                </div>
                                <div className="item_description">
                                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an</p>
                                </div>
                            </div>
                        </div>
                        <div className='item_box watchlist_box'>
                            <div onClick={handleShow}>
                                <img className="img" src="/img/tranding_seven.png" />
                            </div>
                            <div className="item_img_details">
                                <div className="item_img_header">
                                    <h5>Friends</h5>
                                    <ul>
                                        <li><Link to={""}><img src="img/play-icon.png" alt=""></img></Link></li>
                                        <li><Link to={""}><img src="img/add-icon.png" alt=""></img></Link></li>
                                        <li><Link to={""}><img src="img/like-icon.png" alt=""></img></Link></li>
                                    </ul>
                                </div>
                                <div className="item_img_number">
                                    <h3>U/A 16+</h3>
                                    <h4>2022 &nbsp;|&nbsp; Commedy</h4>
                                </div>
                                <div className="item_description">
                                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an</p>
                                </div>
                            </div>
                        </div>

                        <div className='item_box watchlist_box'>
                            <div onClick={handleShow}>
                                <img className="img" src="/img/img-4.png" />
                            </div>
                            <div className="item_img_details">
                                <div className="item_img_header">
                                    <h5>Friends</h5>
                                    <ul>
                                        <li><Link to={""}><img src="img/play-icon.png" alt=""></img></Link></li>
                                        <li><Link to={""}><img src="img/add-icon.png" alt=""></img></Link></li>
                                        <li><Link to={""}><img src="img/like-icon.png" alt=""></img></Link></li>
                                    </ul>
                                </div>
                                <div className="item_img_number">
                                    <h3>U/A 16+</h3>
                                    <h4>2022 &nbsp;|&nbsp; Commedy</h4>
                                </div>
                                <div className="item_description">
                                    <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Container>
            <Footer />
            <Modal className='series_modal' show={show} onHide={handleClose} backdrop="static" size="lg" keyboard={false} centered>
                <div className="modal_close" onClick={handleClose}>
                    <Link to={""}><img src="img/modal_close.png" alt=""></img></Link>
                </div>
                <Modal.Body>
                    <div className="series_img">
                        <img src="img/series_img.png" className="series_big_imf" alt=""></img>
                        <div className="series_tool">
                            <div className="series_name">
                                <h2>Movie Name</h2>
                            </div>
                            <div className="series_controller">
                                <div className="series_left_controller">
                                    <button className="music_play_btn"><img src="img/play_icon.jpg"></img>Play</button>
                                    <a href="#"><img src="img/music_add.png" className="music_small_btn"></img></a>
                                    <a href="#"><img src="img/music_like.png" className="music_small_btn"></img></a>
                                </div>
                                <div className="series_right_controller">
                                    <a href="#"><img src="img/music_volum.png" className="music_small_btn m-0 d-none"></img></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="series_full_description">
                        <div className="series_details_content">
                            <div className="series_description">
                                <div className="more_second_section mt-0">
                                    <h3>U/A 16+</h3>
                                    <h4>2022 &nbsp;|&nbsp; 2h 30m</h4>
                                </div>
                                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                            </div>
                            <div className="series_categories">
                                <h4>Cast : <span className="white_text">Actor Name</span></h4>
                                <h4>Genres  : <span className="white_text">Drama</span></h4>
                            </div>
                        </div>
                        <div className="series_episode_details">
                            <div className="series_episode_header">
                                <h3>Episode</h3>
                                <Genres />
                            </div>
                        </div>
                        <div className="series_episode_list">
                            <div className="series_episode_list_box">
                                <Link to={""}>
                                    <div className="series_episode_list_box_details">
                                        <h4>1</h4>
                                        <div className="episode_details_img">
                                            <img src="img/episode_img.png" className="episode_img" alt=""></img>
                                            <Link to={""}><img src="img/play_video.png" className="play_video_icon" alt=""></img></Link>
                                        </div>
                                        <div className="series_episode_list_box_description">
                                            <h3>Lorem Ipsum is simply dummy text</h3>
                                            <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                                when an unknown printer took a galley of type and scrambled it to make a type</p>
                                        </div>
                                    </div>
                                    <div className="series_episode_list_box_min">
                                        <h2>50m</h2>
                                    </div>
                                </Link>
                            </div>
                            <div className="series_episode_list_box">
                                <Link to={""}>
                                    <div className="series_episode_list_box_details">
                                        <h4>2</h4>
                                        <div className="episode_details_img">
                                            <img src="img/episode_img.png" className="episode_img" alt=""></img>
                                            <Link to={""}><img src="img/play_video.png" className="play_video_icon" alt=""></img></Link>
                                        </div>
                                        <div className="series_episode_list_box_description">
                                            <h3>Lorem Ipsum is simply dummy text</h3>
                                            <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                                when an unknown printer took a galley of type and scrambled it to make a type</p>
                                        </div>
                                    </div>
                                    <div className="series_episode_list_box_min">
                                        <h2>50m</h2>
                                    </div>
                                </Link>
                            </div>
                            <div className="series_episode_list_box">
                                <Link to={""}>
                                    <div className="series_episode_list_box_details">
                                        <h4>3</h4>
                                        <div className="episode_details_img">
                                            <img src="img/episode_img.png" className="episode_img" alt=""></img>
                                            <Link to={""}><img src="img/play_video.png" className="play_video_icon" alt=""></img></Link>
                                        </div>
                                        <div className="series_episode_list_box_description">
                                            <h3>Lorem Ipsum is simply dummy text</h3>
                                            <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                                when an unknown printer took a galley of type and scrambled it to make a type</p>
                                        </div>
                                    </div>
                                    <div className="series_episode_list_box_min">
                                        <h2>50m</h2>
                                    </div>
                                </Link>
                            </div>
                            <div className="series_episode_list_box">
                                <Link to={""}>
                                    <div className="series_episode_list_box_details">
                                        <h4>4</h4>
                                        <div className="episode_details_img">
                                            <img src="img/episode_img.png" className="episode_img" alt=""></img>
                                            <Link to={""}><img src="img/play_video.png" className="play_video_icon" alt=""></img></Link>
                                        </div>
                                        <div className="series_episode_list_box_description">
                                            <h3>Lorem Ipsum is simply dummy text</h3>
                                            <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                                when an unknown printer took a galley of type and scrambled it to make a type</p>
                                        </div>
                                    </div>
                                    <div className="series_episode_list_box_min">
                                        <h2>50m</h2>
                                    </div>
                                </Link>
                            </div>
                            <div className="series_episode_list_box">
                                <Link to={""}>
                                    <div className="series_episode_list_box_details">
                                        <h4>5</h4>
                                        <div className="episode_details_img">
                                            <img src="img/episode_img.png" className="episode_img" alt=""></img>
                                            <Link to={""}><img src="img/play_video.png" className="play_video_icon" alt=""></img></Link>
                                        </div>
                                        <div className="series_episode_list_box_description">
                                            <h3>Lorem Ipsum is simply dummy text</h3>
                                            <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                                when an unknown printer took a galley of type and scrambled it to make a type</p>
                                        </div>
                                    </div>
                                    <div className="series_episode_list_box_min">
                                        <h2>50m</h2>
                                    </div>
                                </Link>
                            </div>
                            <div className="series_episode_list_box">
                                <Link to={""}>
                                    <div className="series_episode_list_box_details">
                                        <h4>6</h4>
                                        <div className="episode_details_img">
                                            <img src="img/episode_img.png" className="episode_img" alt=""></img>
                                            <Link to={""}><img src="img/play_video.png" className="play_video_icon" alt=""></img></Link>
                                        </div>
                                        <div className="series_episode_list_box_description">
                                            <h3>Lorem Ipsum is simply dummy text</h3>
                                            <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                                when an unknown printer took a galley of type and scrambled it to make a type</p>
                                        </div>
                                    </div>
                                    <div className="series_episode_list_box_min">
                                        <h2>50m</h2>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="more_like_this">
                            <div className="more_like_this_title">
                                <h3>More Like This</h3>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-6">
                                    <div className="more_like_box">
                                        <div className="more_like_box_img">
                                            <img src="img/more_1.png"></img>
                                        </div>
                                        <div className="more_like_content_box">
                                            <div className="more_like_left_info">
                                                <h4>Lorem Ipsum is simply dummy text</h4>
                                                <Link to={""}><img src="img/music_add.png" className="music_small_btn"></img></Link>
                                            </div>
                                            <div className="more_second_section">
                                                <h3>U/A 16+</h3>
                                                <h4>2022</h4>
                                            </div>
                                            <div className="more_second_description">
                                                <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-6 ">
                                    <div className="more_like_box">
                                        <div className="more_like_box_img">
                                            <img src="img/more_2.png"></img>

                                        </div>
                                        <div className="more_like_content_box">
                                            <div className="more_like_left_info">
                                                <h4>Lorem Ipsum is simply dummy text</h4>
                                                <Link to={""}><img src="img/music_add.png" className="music_small_btn"></img></Link>
                                            </div>
                                            <div className="more_second_section">
                                                <h3>U/A 16+</h3>
                                                <h4>2022</h4>
                                            </div>
                                            <div className="more_second_description">
                                                <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-6">
                                    <div className="more_like_box">
                                        <div className="more_like_box_img">
                                            <img src="img/more_3.png"></img>
                                        </div>
                                        <div className="more_like_content_box">
                                            <div className="more_like_left_info">
                                                <h4>Lorem Ipsum is simply dummy text</h4>
                                                <Link to={""}><img src="img/music_add.png" className="music_small_btn"></img></Link>
                                            </div>
                                            <div className="more_second_section">
                                                <h3>U/A 16+</h3>
                                                <h4>2022</h4>
                                            </div>
                                            <div className="more_second_description">
                                                <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="more_img_load">
                                <Link to={""}><img src="img/down_arrowpng.png" alt=""></img></Link>
                            </div>
                        </div>
                        <div className="about_movie">
                            <div className="more_like_this_title">
                                <h3>About Movie Name</h3>
                            </div>
                            <div className="about_movie_details">
                                <div className="series_categories">
                                    <h4>Cast : <span className="white_text">Actor Name, Actor Name,</span></h4>
                                    <h4>Crew : <span className="white_text">Andrzej Sapkowski, Andrzej Sapkowski, Lauren Schmidt, Matthew D'Ambrosio, Haily Hall, Declan De Barra</span></h4>
                                    <h4>Genres  : <span className="white_text">Drama</span></h4>
                                    <h4>Maturity Rating: <span className="white_text white_text_border">U/A 16+</span></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
        </div>
    )
}
