export default {
    signin                  :   "/signin",
    signup                  :   "/signup",
    forgot                  :   "/forgot",
    otp                     :   "/otp",
    changePassword          :  "/changePassword",
    header                  : "/header",
    footer                  : "/footer",
    movie                   : "/movie",
    tvShows                 : "/tvShows",
    genresmovie             : "/genres/wise/movies/:genresId",
    genrestvshow            : "/genres/wise/tvshow/:genresId",
    watchlist               : "/watchlist",
    switchProfile           : "/switch-profile",
    accountSetting          : "/setting",
    accountChangePassword   : "/change",
    billingDetails          : "/billing-details",
    plans                   : "/plans",
    payment                 : "/payment",
    viewAll                 : "/view/:types",
    viewAllhome             : "/ContinueWatching/:types",
    viewAllRTVshow          : "/viewall/tvshows/:types",
    home                    : "/home",
    activeAccount           : "/active/acccount",
    selectGenres            : "/selectGenres",
    VideoWatch              : "/video/watch",
    SearchData              : "/search/",
    Privacy                 : "/privacy-policy/",
    comingSoon              : "/coming-soon",
    closeAccount            :"/close/account",
    purchase                :"/purchase",
    itemPurchase            :"/item/purchase",

    NotFound                : "/*",
}