import {BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import SignIn from './pages/users/SignIn'
import SignUp from './pages/users/SignUp'
import routes from './pages/Routes'
import ForgotPassword from './pages/users/ForgotPassword'
import Otp from './pages/users/Otp'
import ChangePassword from './pages/users/ChangePassword'
import Home from './pages/Home'
import Movie from './pages/Movie'
import TVShows from './pages/TVShows'
import GenreswiseMovie from './pages/GenreswiseMovie'
import GenreswiseTvshow from './pages/GenreswiseTvshow'
import Watchlist from './pages/Watchlist'
import SwitchProfile from './pages/SwitchProfile'
import AccountSetting from './pages/AccountSetting'
import AccountChangePassword from './pages/AccountChangePassword'
import BillingDetails from './pages/BillingDetails'
import Plans from './pages/Plans'
import Payment from './pages/Payment'
import ViewAll from './pages/ViewAll'
import Landing from './pages/Landing'
import Protected from './Protected'
import SelectGenres from './pages/SelectGenres'
import VideoPlay from './pages/VideoWatch'
import SearchData from './pages/SearchData'
import PageNotFound from './pages/NotFound'
import ViewAllTvshow from './pages/ViewAllTVshow'
import Viewallhome from './pages/Viewallhome'
import Active from './pages/users/Active'
import Privacy from './pages/Privacy'
import ComingSoon from './pages/ComingSoon'
import ItemPurchase from './pages/ItemPurchase'
import { useEffect, useState } from 'react'
import { API_URLS, API_URL_BASE, X_APP_SECRET } from './pages/utils'
import { FeaturesData, Session, User, UserCountry, UserLocationData } from './pages/Helper'
import secureLocalStorage from 'react-secure-storage'
import CloseAcccount from './pages/CloseAcccount'
import Purchase from './pages/Purchase'

export default function App() {

  
  var user = User();
  const token = user?.data.token
  var session = Session();

  const FeaturesList = FeaturesData();
  const [UserLogin, setUserLogin] = useState('');

  useEffect(() => {

    if( FeaturesList !== null && FeaturesList.data){
      
      setUserLogin(FeaturesList.data.login);  

    }
    
    const fetchFeatureListData = async () => {

      try {
          const featuresData = secureLocalStorage.getItem('features-Data') as string;
          const featuresListData = featuresData ? JSON.parse(featuresData) : null;
        
          const now = new Date();
          const expirationTimeMs = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
          // const expirationTimeMs = 2 * 60 * 1000; // 2 minutes in milliseconds

          if (featuresListData && featuresListData.expiration) {
              // If featuresListData exists and contains an expiration property
              setTimeout(() => {
                  // Remove features-Data from localStorage after expiration time
                  const now = new Date();
                  if (now.getTime() > featuresListData.expiration) {
                      secureLocalStorage.removeItem('features-Data');
                  }
              }, expirationTimeMs);
  
              if (!featuresListData.expiration) {
                  // Throw an error if expiration time is not found
                  throw new Error("Expiration time not found in features-Data item");
              }
  
              if (now.getTime() > featuresListData.expiration) {
                  // Remove features-Data from localStorage if expiration time has passed
                  secureLocalStorage.removeItem('features-Data');
              }
          }
  
          if (!featuresData) {
              // Fetch features list data if not available in localStorage
              const response = await fetch(`${API_URL_BASE}${API_URLS.FEATURES_LIST}`, {
                  method: "GET",
                  headers: {
                      Accept: "application/json",
                      "Content-type": "application/json",
                      'X-APP-SECRET': X_APP_SECRET,
                      Authorization: "Bearer " + token,
                  },
              });
              const responseData = await response.json();
              if (response.ok) {
                  setUserLogin(responseData.data.login);
                  
                  if (typeof responseData.data === 'object' && responseData.data !== null) {
                    // Create a new object to hold both the original data and the expiration
                    const featuresDataWithExpiration = {
                        data: responseData.data, // Store the original data
                        expiration: now.getTime() + expirationTimeMs // Add the expiration field
                    };
                    secureLocalStorage.setItem("features-Data", JSON.stringify(featuresDataWithExpiration));
                  } else {
                    console.error("Error fetching data: responseData.data is not an object or is null");
                }
                
              } else {
                  console.error("Error fetching data:", responseData.error);
              }
          }
      } catch (error) {
          console.warn("Error fetching data:", error);
      }
  };
  



    const fetchUserLocationData = async () => {
      try {
        const userLocationStr = secureLocalStorage.getItem('user-location') as string;
        const userLocation = userLocationStr ? JSON.parse(userLocationStr) : null;
      
          const now = new Date(); 
          const expirationTimeMs = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
          
          if (userLocationStr && userLocation.expiration) {
              const userLocation = JSON.parse(userLocationStr);

              setTimeout(() => {
                  const now = new Date();

                  if (now.getTime() > userLocation.expiration) {
                      secureLocalStorage.removeItem('user-location');
                  }
              }, expirationTimeMs);
  
              if (!userLocation.expiration) {
                  throw new Error("Expiration time not found in user location item");
              }
  
              if (now.getTime() > userLocation.expiration) {
                  secureLocalStorage.removeItem('user-location');
              }
          }

          if (!userLocationStr) {
                
            // alert("User Location APi Call");
              const response = await fetch(`${API_URL_BASE}${API_URLS.USER_LOCATION}`, {
                  method: "GET",
                  headers: {
                      Accept: "application/json",
                      "Content-type": "application/json",
                      'X-APP-SECRET': X_APP_SECRET,
                      Authorization: "Bearer " + token,
                  },
              });
              const responseData = await response.json();
              if (response.ok) {
                if (typeof responseData.data === 'object' && responseData.data !== null) {
                    const dataWithExpiration = {
                        ...responseData.data,
                        expiration: now.getTime() + expirationTimeMs
                    };
                    secureLocalStorage.setItem("user-location", JSON.stringify(dataWithExpiration));
                } else {
                    console.error("Error fetching data: responseData.data is not an object or is null");
                }
            } else {
                console.error("Error fetching data:", responseData.error);
            }
          }
      } catch (error) {
          console.warn("Error fetching data:", error);
      }
    };

  fetchFeatureListData();
  fetchUserLocationData();
  },[]);


  
  const loggedInRoutes = (
        <>
          <Route path={routes.watchlist} element={<Protected Component={Watchlist} />}></Route>
          <Route path={routes.switchProfile} element={<Protected Component={SwitchProfile} />}></Route>
          <Route path={routes.accountSetting} element={<Protected Component={AccountSetting} />}></Route>
          <Route path={routes.accountChangePassword} element={<Protected Component={AccountChangePassword} />}></Route>
          <Route path={routes.billingDetails} element={<Protected Component={BillingDetails} />}></Route>
          <Route path={routes.plans} element={<Protected Component={Plans} />}></Route>
          <Route path={routes.payment} element={<Protected Component={Payment} />}></Route>

          <Route path={"/"} element={<Protected Component={Landing} />}></Route>
          <Route path={routes.home} element={<Protected Component = {Home}/>}></Route>
          <Route path={routes.movie} element={<Protected Component = {Movie}/>}></Route>
          <Route path={routes.tvShows} element={<Protected Component = {TVShows}/> }></Route>
          <Route path={routes.viewAll} element={<Protected Component = {ViewAll}/>}></Route>
          <Route path={routes.viewAllhome} element={<Protected Component = {Viewallhome}/>}></Route>
          <Route path={routes.viewAllRTVshow} element={<Protected Component = {ViewAllTvshow}/>}></Route>
          <Route path={routes.genresmovie} element={<Protected Component = {GenreswiseMovie} />}></Route>
          <Route path={routes.genrestvshow} element={<Protected Component = {GenreswiseTvshow} />}></Route>
          <Route path={routes.selectGenres} element={<Protected Component = {SelectGenres}/>}></Route>
          <Route path={routes.SearchData} element={<Protected Component = {SearchData}/>}></Route>
          <Route path={routes.VideoWatch} element={<Protected Component = {VideoPlay} />}></Route>
          <Route path={routes.closeAccount} element={<Protected Component = {CloseAcccount} />}></Route>
          <Route path={routes.signin} element={<SignIn/>}></Route>
          <Route path={routes.signup} element={<SignUp/>}></Route>
          <Route path={routes.forgot} element={<ForgotPassword/>}></Route>
          <Route path={routes.otp} element={<Otp/>}></Route>
          <Route path={routes.changePassword} element={<ChangePassword/>}></Route>
          <Route path={routes.activeAccount} element={<Active/>}></Route>
          <Route path={routes.Privacy} element={<Privacy/>}></Route>
          <Route path={routes.NotFound} Component={PageNotFound}></Route>
          <Route path={routes.comingSoon} element={<ComingSoon/>}></Route>
          <Route path={routes.purchase} element={<Purchase/>}></Route>
          <Route path={routes.itemPurchase}  element={<ItemPurchase data_id="" data_type=""  updatePaymentStatus={() => {}} errorInVideoPayment={() => {}} loderShow={() => {}}  loderHide={() => {}}  />}></Route>
          
          


        </>
  );

  const nonLoggedInRoutes = (
          <>
            <Route path={"/"} element={<Landing/>}></Route>
            <Route path={routes.home} element={<Home/>}></Route>
            <Route path={routes.movie} element={<Movie/>}></Route>
            <Route path={routes.tvShows} element={<TVShows/> }></Route>
            <Route path={routes.viewAll} element={<ViewAll/>}></Route>
            <Route path={routes.viewAllhome} element={<Viewallhome/>}></Route>
            <Route path={routes.viewAllRTVshow} element={<ViewAllTvshow/>}></Route>
            <Route path={routes.genresmovie} element={<GenreswiseMovie />}></Route>
            <Route path={routes.genrestvshow} element={<GenreswiseTvshow />}></Route>
            <Route path={routes.selectGenres} element={<SelectGenres/>}></Route>
            <Route path={routes.SearchData} element={<SearchData/>}></Route>
            <Route path={routes.VideoWatch} element={<VideoPlay />}></Route>  
            
            <Route path={routes.signin} element={<SignIn/>}></Route>
            <Route path={routes.signup} element={<SignUp/>}></Route>
            <Route path={routes.forgot} element={<ForgotPassword/>}></Route>
            <Route path={routes.otp} element={<Otp/>}></Route>
            <Route path={routes.changePassword} element={<ChangePassword/>}></Route>
            <Route path={routes.activeAccount} element={<Active/>}></Route>
            <Route path={routes.Privacy} element={<Privacy/>}></Route>
            <Route path={routes.NotFound} Component={PageNotFound}></Route>
            <Route path={routes.comingSoon} element={<ComingSoon/>}></Route>
            
            <Route path={routes.watchlist} element={<Protected Component={Watchlist} />}></Route>
            <Route path={routes.switchProfile} element={<Protected Component={SwitchProfile} />}></Route>
            <Route path={routes.accountSetting} element={<Protected Component={AccountSetting} />}></Route>
            <Route path={routes.accountChangePassword} element={<Protected Component={AccountChangePassword} />}></Route>
            <Route path={routes.billingDetails} element={<Protected Component={BillingDetails} />}></Route>
            <Route path={routes.plans} element={<Protected Component={Plans} />}></Route>
            <Route path={routes.payment} element={<Protected Component={Payment} />}></Route>
            <Route path={routes.closeAccount} element={<Protected Component = {CloseAcccount} />}></Route>
          </>
  );

  return (
      <div>
       <Routes>
      {UserLogin || token || session ?(loggedInRoutes) :(nonLoggedInRoutes)}
      </Routes>
      </div>
  )
}

