import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { SyntheticEvent } from 'react';
import { User, Session, FormatTime, calculatePercentageOfTime, SumTimeDurations, FeaturesData, UserLocationData } from './Helper';
import { API_URL_BASE, API_URLS, headers, X_APP_SECRET } from './utils';
import React, { useEffect, useState } from "react";
import ReactJWPlayer from "react-jw-player";
import { first } from 'lodash';

declare global {
  interface Window {
    jwplayer: any; // Use a more specific type if available
  }
}

const VideoPlay: React.FC = () => {
  const [isVastLoaded, setIsVastLoaded] = useState(false);
  const [AdvertiseMentUrls, setAdvertiseMentUrls] = useState<string[]>([]);
  const [SingleAdvertiseMentUrls, setSingleAdvertiseMentUrls] = useState<string>('');
  const navigate = useNavigate();
  // const { data_id, data_type,TVShow_id,watching_time } = useParams();
  const user = User();
  const token = user?.data.token;
  const session = Session();

  const [videoDuration, setVideoDuration] = useState<number | null | string>(null);
  const [videoCurrentTime, setVideoCurrentTime] = useState<number | string>(0);
  const [videoUrl, setVideoUrl] = useState<string>('false');
  const [vastTagUrl, setvastTagUrl] = useState<string>('');
  const [adUrl, setAdUrl] = useState<string>('');
  const [startTime, setStartTime] = useState<string>();

  const location = useLocation();
  const { data_id } = location.state || {};
  const { data_type } = location.state || {};
  const { watching_time } = location.state || {};
  const { TVShow_id } = location.state || {};
  const FeaturesList = FeaturesData();
  const UserLocation = UserLocationData();


  const [newScheduleArray, setNewScheduleArray] = useState<any[]>([]);
  const [videoPlayPercentage, setvideoPlayPercentage] = useState<number>(30);


  useEffect(() => {

    fetch(`${API_URL_BASE}${API_URLS.FEATURES_LIST}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
        'X-APP-SECRET': X_APP_SECRET,
        Authorization: "Bearer " + token,
    },

    }).then((result) => {

        result.json().then((resp) => {
            if(resp.response == true) {    
              setvideoPlayPercentage(resp.data.video_play_time);
            } 
        })
    })

    const fetchVastTagUrl = () => {
      const requestData = {
        profile_id: session?.profile_id,
        country_code: UserLocation.country_code
      };

      const requestBody = JSON.stringify(requestData);

      fetch(`${API_URL_BASE}${API_URLS.ADVERTISEMENT_URL}`, {
        method: 'POST',
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          'X-APP-SECRET': X_APP_SECRET,
          Authorization: "Bearer " + token,
      },
        body: requestBody,
      })
        .then((result) => {
          if (!result.ok) {
            throw new Error('API request failed');
          }
          return result.json();
        })
        .then((resp) => {
          if (resp.response === true) {
            // setvastTagUrl(resp.data.tag_url);
            fetch(resp.data.tag_url)
              .then(response => response.text())
              .then(vmapContent => {
                // Parse the VMAP XML to extract VAST URLs
                try {
                  const parser = new DOMParser();
                  const vmapXml = parser.parseFromString(vmapContent, "application/xml");
                  const adTags = vmapXml.querySelectorAll("AdTagURI");
                  const urls = Array.from(adTags).map(tag => tag.textContent?.trim() || "");
                  // console.warn(urls,"##########")
                  // if(urls.length === 0){
                  //   setSingleAdvertiseMentUrls(vastTagUrl)
                  // }else{
                  //   setAdvertiseMentUrls(urls);
                  // }
                  if (urls.length === 0) {
                    // setSingleAdvertiseMentUrls(resp.data.tag_url);
                    setNewScheduleArray([
                      {
                        tag: [resp.data.tag_url],
                        type: "linear",
                        offset: "10",
                      },
                    ]);
                  } else {
                    // setAdvertiseMentUrls(urls);
                    setNewScheduleArray(
                      urls.map((url, index, array) => ({
                        tag: [url],
                        type: "linear",
                        offset: index === 0 ? "pre" : index === array.length - 1 ? "post" : "15",
                      }))
                    );
                  }
                } catch (parseError) {
                  console.error("Error parsing VMAP XML:", parseError);
                }
              })
              .catch(error => {
                console.error("Error loading VMAP URL:", error);
              });

          } else {
            alert('API request succeeded, but the response indicates an error');
          }
        })
        .catch((error) => {
          console.error('API request error:', error);
        });
    };
    fetchVastTagUrl();
  }, []);


  const onVideoLoaded = () => {
    const player = window.jwplayer("JWPlayer");
    player.on('play', function () {
      /* Get Video Duration */
      
      const duration = player.getDuration();
      const time = FormatTime(duration);
      setVideoDuration(time);

      /* Video start Time */
      
      const startTime = player.getPosition();
      const formattedStartTime = FormatTime(startTime);
      setStartTime(formattedStartTime);
      
      

      
    });
  };

  const onTimeUpdate = () => {
    const player = window.jwplayer("JWPlayer");

    // Event when the playback time is updated
    player.on('time', function (event: any) {
      const currentTime = event.position; 
      const formattedTime = FormatTime(currentTime);

      setVideoCurrentTime(formattedTime);
    });
  };
    
  const [imageDisabled, setImageDisabled] = useState(false);

  

  const handleCloseVideo = () => {

    setImageDisabled(true); 

    if(token){

      const requestData = {
        profile_id: session?.profile_id,
        data_type: data_type === 'movie' ? 'movie' : 'tvshow',
        data_id: data_id,
        video_duration: videoDuration,
        watching_duration: videoCurrentTime,
        country_code: UserLocation.country_code
      };

      const requestBody = JSON.stringify(requestData);

      fetch(`${API_URL_BASE}${API_URLS.CONTINUE_WATCHING_ADD}`, {
        method: 'POST',
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          'X-APP-SECRET': X_APP_SECRET,
          Authorization: "Bearer " + token,
      },
        body: requestBody,
      }).then((result) => {
        result.json().then((resp) => {

          if (resp.response === true) {
            // navigate(-1); // Go back one step in the history
          }
        });
      }); 
      
      const requiredPlaytime   = calculatePercentageOfTime(String(videoDuration),videoPlayPercentage);
      const totalVideoPlayTime = SumTimeDurations(String(startTime),String(videoCurrentTime));

      if( totalVideoPlayTime  > requiredPlaytime ){

        const CapturerequestData = {
          profile_id: session?.profile_id,
          data_type: data_type === 'movie' ? 'movie' : 'tvshow',
          movie_id: data_type === 'movie' ? data_id : '',
          episode_id:data_type === 'movie' ? "" : data_id,
          tvshow_id:data_type === 'movie' ? "" : TVShow_id,
          geo_location:UserLocation.country_code,
          start_duration: startTime,
          end_duration: videoCurrentTime,
        };
  
        const CaptureBody = JSON.stringify(CapturerequestData);

        fetch(`${API_URL_BASE}${API_URLS.USER_DATA_CAPTURE}`, {
          method: 'POST',
          headers: headers,
          body: CaptureBody,
        }).then((result) => {
          result.json().then((resp) => {
            console.warn(resp)
            if (resp.response === true) {
            }
          });
        });
      }
      navigate(-1);
    }else{
      navigate(-1);
    }

    setTimeout(() => {
      setImageDisabled(false);
  }, 3000);
      
  };

  // let newScheduleArray = [
  // {
  //   tag: [
  //     "https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/single_ad_samples&sz=640x480&cust_params=sample_ct%3Dlinear&ciu_szs=300x250%2C728x90&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator="
  //   ],
  //   type: "linear",
  //   offset: "10",  // Set the offset to "pre" for preroll
  // }
  // ];

  const ads = {
    admessage: "This video will resume in xx seconds",
    adscheduleid: "adscheduledid",
    client: "vast",
    cuetext: "Advertisement",
    outstream: false,
    preloadAds: true,
    vpaidcontrols: false,
    rules: {
      startOnSeek: "pre",
      timeBetweenAds: 0
    },
    schedule: newScheduleArray,
  };

  useEffect(() => {
    // Fetch video URL
    const fetchVideoUrl = () => {
      const requestData = {
        profile_id: session?.profile_id,
        data_id: Number(data_id),
        data_type: data_type,
        country_code: UserLocation.country_code
      };

      const requestBody = JSON.stringify(requestData);

      fetch(`${API_URL_BASE}${API_URLS.PLAY_VIDEO}`, {
        method: 'POST',
        headers: headers,
        body: requestBody,
      })
        .then((result) => {
          if (!result.ok) {
            throw new Error('API request failed');
          }
          return result.json();
        })
        .then((resp) => {
          if (resp.response === true) {
            // setVideoUrl(resp.data.video_url);
            setVideoUrl(resp.data.video_url);
          } else {
            alert('API request succeeded, but the response indicates an error');
          }
        })
        .catch((error) => {
          console.error('API request error:', error);
        });
    };

    fetchVideoUrl();
  }, []);

  const onReady = (event: any) => {

    const player = window.jwplayer('JWPlayer');
    
    if (watching_time !== undefined) {
      
      const timeParts = watching_time.split(':');
      if (timeParts.length === 3) {
        const hours = parseInt(timeParts[0], 10);
        const minutes = parseInt(timeParts[1], 10);
        const seconds = parseInt(timeParts[2], 10);
        const totalSeconds = hours * 3600 + minutes * 60 + seconds;

        // Use the seek method to set the initial playback position
        player.seek(totalSeconds);
      }
    }
  }
  return (
    <div className="App">
      {videoUrl !== "" ? (
        <ReactJWPlayer
          file={videoUrl}
          onVideoLoad={onVideoLoaded}
          onTime={onTimeUpdate}
          onReady={onReady}
          playerId="JWPlayer"
          playerScript="https://cdn.jwplayer.com/libraries/cDnha7c4.js"
          customProps={{
            advertising: { ...ads },
            autostart: true
          }}
        />
      ) : (
        <div className="App">
          <div className="error_page">
            <img src="/img/404.svg"></img>
            <h1>Video Not Available.</h1>
          </div>
        </div>
      )}
      
      <button className='video_play_btn_btn' onClick={handleCloseVideo} disabled={imageDisabled} >
        <img className="video_play_btn" src="/img/left-arrow.png" alt="Close Video" />
      </button>
    </div>
  );
};

export default VideoPlay;
