import React from 'react'
import { Link } from 'react-router-dom'
import { useState } from 'react';
import { useEffect } from 'react';
import routes from '../Routes'
import { API_URL_BASE, X_APP_SECRET, headers } from './../utils'
import { API_URLS } from './../utils'
import { useNavigate, Navigate } from 'react-router-dom'
import { IStateSignup } from '../Model/Interface';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';
import HCaptcha from 'react-hcaptcha';
import secureLocalStorage from 'react-secure-storage';

const SignUp: React.FC = () => {
    const navigate = useNavigate();
    const [genders, setGender] = useState("");
    const [dobs, setDob] = useState("");
    const [startDate, setStartDate] = useState<any>(null);
    const [inputType, setInputType] = useState('date');
    const [captchaValue, setCaptchaValue] = useState<string | null>(null);


    const handleBlur = () => {
        setInputType('text');
    };

    const handleFocus = () => {
        setInputType('date');
    };

    useEffect(() => {
        if (secureLocalStorage.getItem("playnow-user")) {
            navigate(routes.home)
        }
    })
    const [state, setState] = useState<IStateSignup>({
        user: {
            first_name: "",
            last_name: "",
            email: "",
            password: ""
        },
    });

    const [errmsg, setErrormsg] = useState("");

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setState({
            user: {
                ...state.user,
                [event.target.name]: event.target.value,
            },
        });
    };
    const Changegender = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setGender(event.target.value);

    }
    const registerUser = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        // Your form submission logic here
        var formattedDate = "";
        if (startDate != null) {
            var formattedDate = format(startDate, 'yyyy-MM-dd');
        }

        let email = state.user.email;
        let Preemail = state.user.email;
        let password = state.user.password;
        let first_name = state.user.first_name;
        let last_name = state.user.last_name;
        let gender = genders;
        // let birth_date = `${month}/${date}/${year}`;
        // let birth_date = `${year}-${month}-${date}`;
        let birth_date = formattedDate;

        if (gender == "") {
            gender = "3";
        }

        let data = { email, password, first_name, last_name, birth_date, gender }
        fetch(`${API_URL_BASE}${API_URLS.SIGN_UP}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
              },
            body: JSON.stringify(data)
        }).then((result) => {

            result.json().then((resp) => {
                if (resp.response == false) {

                    setErrormsg(resp.message);
                } else {
                    // navigate(routes.activeAccount)
                    navigate(routes.activeAccount, { state: { Preemail } });
                }
            })
        })

        // const month = startDate?.getMonth() + 1;
        // const year = startDate?.getFullYear();
        // const date = startDate?.getDate();

    }

    // const handleCaptchaVerify = async (token: string | null) => {
    //     alert(token)
    //     try {
    //       const response = await fetch('/verify-captcha', {
    //         method: 'POST',
    //         headers: {
    //           'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify({ token }),
    //       });

    //       const result = await response.json();

    //       if (result.success) {
    //         // hCaptcha verification successful
    //         console.log('hCaptcha verification successful');
    //         setCaptchaValue(token);
    //       } else {
    //         // hCaptcha verification failed
    //         console.error('hCaptcha verification failed:', result.error);
    //       }
    //     } catch (error) {
    //       console.error('Error verifying hCaptcha:', error);
    //     }
    //   };


    return (
        <>
            <div className="login_bg">
                <div className="login_content">
                    <div className="login_logo">
                        <Link to={"/"} className="logo"><img src="/img/play_now_logo.png"></img></Link>
                    </div>
                    <form onSubmit={registerUser}>

                        <div className="login_box">
                            <div className="login_box_title">
                                <h3>Sign Up</h3>
                            </div>
                            <div className="login_box_content">
                                <div className="input_box">
                                    <span style={{ color: "red" }} >{errmsg}</span>
                                    <input type="text" name="first_name" value={state.user.first_name} onChange={handleChange} className="input_fild" placeholder="First Name"></input>
                                    <input type="text" name="last_name" value={state.user.last_name} onChange={handleChange} className="input_fild" placeholder="Last Name"></input>
                                    <input type="email" name="email" value={state.user.email} onChange={handleChange} className="input_fild" placeholder="Email"></input>
                                    <input type="password" name="password" value={state.user.password} onChange={handleChange} className="input_fild" placeholder="Password"></input>
                                    {/* <label style={{ color:"white"}}>Gender</label> */}
                                    <select className='form_box input_fild' name='genders' value={genders} onChange={Changegender}>
                                        <option value="3">Select Gender</option>
                                        <option value="0">Male</option>
                                        <option value="1">Female</option>
                                        <option value="2">Prefer not to say</option>
                                    </select>
                                    {/* <label style={{ color:"white"}}>Date Of Birth</label> */}
                                    <DatePicker
                                        selected={startDate}
                                        className="input_fild"
                                        // onChange={(e) => setDob(e.target.value)}
                                        onChange={(date) => setStartDate(date)}
                                        maxDate={new Date()}
                                        placeholderText='Birthdate'
                                        fixedHeight
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                    />
                                    {/* <input  type='date' value={dobs} onChange={(e)=>setDob(e.target.value)} className="form_box input_fild"  /> */}

                                    {/* <HCaptcha
                                        sitekey="a463b1b8-6517-4a4a-b315-1a482a028b01"
                                        onVerify={handleCaptchaVerify}
                                        onExpire={() => console.warn('hCaptcha expired')}
                                    /> */}
                                    <input type="submit" className="submit_btn" value="Sign up"></input>
                                    <div className='d-flex justify-content-center'>
                                        <span>Already have an account?</span>
                                        <Link to={"/signin"} className="sign_up_text "><span className="text-white mx-1">Sign in</span></Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
};

export default SignUp;
