import React, { useState, useEffect } from 'react'
import routes from './../Routes'
import { API_URL_BASE, X_APP_SECRET, headers } from './../utils'
import { API_URLS } from './../utils'
import { Link, useNavigate } from 'react-router-dom'
import { IStatesignin } from '../Model/Interface'
import  secureLocalStorage  from  "react-secure-storage";

export default function SignIn() {
    const [state, setState] = useState<IStatesignin>({
        user: {
            email: "",
            password: ""
        },
    });
    const nav = useNavigate()
    const navigate = useNavigate();
    const [checked, setChecked] = useState<boolean>(false);
    const [errmsg, setErrormsg] = useState("");

    const [CheckActiveUser, setCheckActiveUser] = useState(null);
    const [UserLogin, setUserLogin] = useState<boolean>();

    const [data, setData] = useState([])

    const handlecheck = () => {
        setChecked(true);
    };

    useEffect(() => {

        fetch(`${API_URL_BASE}${API_URLS.FEATURES_LIST}`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
            },
    
        }).then((result) => {
    
            result.json().then((resp) => {
                if(resp.response == true) {    
                  setUserLogin(resp.data.login);
                } 
            })
        })

        if (secureLocalStorage.getItem("playnow-user")) {
            navigate(routes.home)
        }
    })

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setState({
            user: {
                ...state.user,
                [event.target.name]: event.target.value,
            },
        });
    };
    const signinUser = (event: React.FormEvent<HTMLFormElement>): void => {
        setCheckActiveUser(null)
        setErrormsg("")
        event.preventDefault();
        let email = state.user.email;
        let password = state.user.password;
        let remember_me = checked;
        let data = { email, password, remember_me }

        fetch(`${API_URL_BASE}${API_URLS.SIGN_IN}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
              },
            body: JSON.stringify(data)
        }).then((result) => {

            result.json().then((resp) => {

                if (resp.response == true) {
                    if (resp.data.is_active == "false") {
                        setErrormsg(resp.message);
                        setCheckActiveUser(resp.data.is_active)
                    } else {
                        secureLocalStorage.setItem("playnow-user",JSON.stringify(resp));
                        navigate(routes.switchProfile)
                    }
                } else {
                    setErrormsg(resp.message);
                }
            })
        })
    }

    const handleLinkClick = () => {

        navigate(routes.activeAccount, { state: { Preemail: state.user.email } });

    }

    return (
        <>
            <div className="login_bg">
                <div className="login_content">
                    <div className="login_logo">
                        <Link to={"/"} className="logo"><img src="/img/play_now_logo.png"></img></Link>
                    </div>
                    <form onSubmit={signinUser}>
                        <div className="login_box">
                            <div className="login_box_title">
                                <h3>Sign in</h3>
                            </div>
                            <div className="login_box_content">
                                <div className="input_box">
                                    <span style={{ color: "red" }} >{errmsg}</span>
                                    {
                                        CheckActiveUser === "false" ? (
                                            <button onClick={handleLinkClick} className="login_box_title active_btn">Active</button>
                                        ) : (
                                            null
                                        )
                                    }
                                    <input type="email" name="email" value={state.user.email} onChange={handleChange} className="input_fild" placeholder="Email"></input>
                                    <input type="password" name="password" value={state.user.password} onChange={handleChange} className="input_fild" placeholder="Password"></input>
                                    <div className="container_check_box small_text_section" >
                                        <label>Remember me
                                            <input type="checkbox" name='remember' onChange={handlecheck}></input>
                                            <span className="checkmark"></span>
                                        </label>
                                        <Link to={"/forgot"} className="forgot_pwd">Forgot Password?</Link>
                                    </div>
                                    <input type="submit" className="submit_btn" value="Sign in" ></input>

                                    <div className='d-flex justify-content-center'>
                                        <span>Don't have an account?</span>
                                        <Link to={"/signup"} className="sign_up_text"> <span className="text-white mx-1">Sign up</span></Link>
                                    </div>

                                    <div className='skip_btn'>
                                        
                                        {UserLogin === false ? (<Link to={routes.home}>Skip</Link>):(null)}
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
