import React, { useEffect, useState } from 'react'
import Header from './Layout/Header'
import { Container } from 'react-bootstrap'
import { Form } from 'react-bootstrap';
import { API_URLS, API_URL_BASE, X_APP_SECRET, headers } from './utils';
import { User } from './Helper';
import { CloseAccountReason, CloseAccountSubmitInterface } from './Model/Interface';
import secureLocalStorage from 'react-secure-storage';
import { useNavigate } from 'react-router-dom';
import routes from './Routes';

export default function CloseAcccount() {
  
  var user = User();
  const token = user?.data.token
  const [closeAccountReasonList, setcloseAccountReasonList] = useState<CloseAccountReason>();
  const [selectedReason, setSelectedReason] = useState<number | null>(null);
  const [Errormsg, setErrormsg] = useState("");
  const navigate = useNavigate();
  
  const handleRadioChange = (value: number) => {
    setSelectedReason(value);
  };

  const [state, setState] = useState<CloseAccountSubmitInterface>({
    data: {
      password: "",
      other_reason: ""
    },
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setState({
        data: {
            ...state.data,
            [event.target.name]: event.target.value,
        },
    });
  };

  useEffect(() => {

    if (token) {

        fetch(`${API_URL_BASE}${API_URLS.CLOSE_ACCOUNT_REASON_LIST}`, {

            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-type": "application/json",
              'X-APP-SECRET': X_APP_SECRET,
              Authorization: "Bearer " + token,
          },

        }).then((result) => {

            result.json().then((resp) => {
              
                if (resp.response == true) {
                  setcloseAccountReasonList(resp.data)
                }
            })
        })
    }
}, [])
  
  const SubmitData = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
  
    const requestData = {
      password:state.data.password,
      reason:selectedReason,
      other_reason:state.data.other_reason,
    };

    const requestBody = JSON.stringify(requestData);    
    if (token) {

      fetch(`${API_URL_BASE}${API_URLS.CLOSE_ACCOUNT}`, {

          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
            'X-APP-SECRET': X_APP_SECRET,
            Authorization: "Bearer " + token,
          },
          body: requestBody,
      }).then((result) => {

          result.json().then((resp) => {
              if (resp.response === true) {
                secureLocalStorage.removeItem('playnow-user');
                secureLocalStorage.removeItem('userProfile');
                secureLocalStorage.removeItem('user-email');
                  navigate(routes.signin);
              }else{
                setErrormsg(resp.message)
              }
          })
      })
    }
  }

  return (
    <div>
        <Header />
        <div className="account_setting">
          <Container fluid className='cust_container'>
                <div className="account_setting_title">
                    <h3>Account Close</h3>
                </div>
                <div className="account_setting_table p-5 close_account">
                  
                  <span style={{ color: "red" }} >{Errormsg}</span>

                  <h3>Please take a moment to let us know why you are leaving:</h3>
                  <Form onSubmit={SubmitData}>
                      { Array.isArray(closeAccountReasonList) && closeAccountReasonList.length > 0 &&  closeAccountReasonList.map((reason:any) => (
                        <Form.Group key={reason.value} controlId={`exampleForm.ControlRadio${reason.value}`}>
                          <Form.Check
                            type="radio"
                            label={reason.label}
                            name="closeAccountReason"
                            id={`formHorizontalRadios${reason.value}`}
                            value={reason.value}
                            checked={selectedReason === reason.value}
                            onChange={() => handleRadioChange(reason.value)}
                          />
                        </Form.Group>
                      ))}
                      { 
                        selectedReason === 8 ?(
                          <div className='input_form'>
                            <label> Explain Reason</label>
                            <input type='text' className='password_box' value={state.data.other_reason} onChange={handleChange}  name='other_reason' placeholder='Explain Reason'  {...(selectedReason === 8 ? { required: true } : {})}  ></input>
                          </div> 
                        ):(null)
                      }
                      <div className='input_form'>
                          <label>Enter Your Password and Close your account</label>
                          <input type='password' name='password' value={state.data.password} onChange={handleChange} className='password_box' placeholder='Enter Password'></input>
                      </div> 

                        <input type='submit' value={"Close Your Account"} className='close_acc_btn'></input>
                  </Form>
                </div>
          </Container>
        </div>
    </div>
  )
}
