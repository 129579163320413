import React, { useEffect, useState } from 'react'
import Header from './Layout/Header'
import { Container } from 'react-bootstrap'
import Table from 'react-bootstrap/Table';
import { BillingDetailsInterface, UserPlanDetailsInterface } from './Model/Interface';
import { User } from './Helper';
import { API_URLS, API_URL_BASE, X_APP_SECRET, headers } from './utils';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

export default function BillingDetails() {

    function showSuccessNotification(message: any) {
        toast.success(message, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    }

    function showErrorNotification(message: any) {
        toast.error(message, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    }

    var user = User();
    const token = user?.data.token
    const [UserPlanDetails, setUserPlanDetails] = useState<UserPlanDetailsInterface>();
    const [CheckUserPlanDetails, setCheckUserPlanDetails] = useState(true);
    const [UserPlanDetailsLoading, setUserPlanDetailsLoading] = useState(true);
    const [UserBillingDetials, setUserBillingDetials] = useState<BillingDetailsInterface>();
    const [BillingLoading, setBillingLoading] = useState(true);
    const [CheckBillingDetials, setCheckBillingDetials] = useState(true);
    


    useEffect(() => {

        if (token) {       
            fetch(`${API_URL_BASE}${API_URLS.USER_ACTIVE_PLAN}`, {

                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-type": "application/json",
                    'X-APP-SECRET': X_APP_SECRET,
                    Authorization: "Bearer " + token,
                },
            }).then((result) => {

                result.json().then((resp) => {

                    if (resp.response == true) {
                        setUserPlanDetails(resp.data)
                        setUserPlanDetailsLoading(false)
                    }else{
                        setCheckUserPlanDetails(false)
                    }
                })
            })
            
            fetch(`${API_URL_BASE}${API_URLS.USER_BILLING_DETAILS}`, {

                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-type": "application/json",
                    'X-APP-SECRET': X_APP_SECRET,
                    Authorization: "Bearer " + token,
                },

            }).then((result) => {

                result.json().then((resp) => {
                    
                    if (resp.response == true) {
                        setUserBillingDetials(resp.data)
                        setBillingLoading(false)
                    }else{
                        setCheckBillingDetials(false)
                    }
                })
            })

        }
    }, [])



    return (
        <div>
            <Header />
            <ToastContainer />
            <div className="account_setting">
                <Container fluid className='cust_container'>
                    <div className="account_setting_title">
                        <h3>Billing Details</h3>
                    </div>
                    <div className="account_setting_table p-0">
                        <div className="billing_details">
                        {
                            CheckUserPlanDetails ? (
                                <>
                                    {UserPlanDetails ? (
                                         <>
                                            <div className="billing_left_details">
                                                {UserPlanDetails ? <h4>{UserPlanDetails['plan_name']}</h4> : <h5>Subscription Not Found.</h5>}
                                            </div>
                                            <div className="billing_left_details">
                                                {UserPlanDetails ? <h4>Next billing date</h4> : null}
                                                {UserPlanDetails ? <h5>{UserPlanDetails['next_bill_date']}</h5> : null}
                                            </div>
                                        </>
                                    ):(
                                        Array.from({ length: 2 }).map((_, index) => (
                                            <div className="subscription_shimmer_card" key={index}></div>
                                        )) 
                                    )}                                      
                                </>
                            ) : (
                                <>
                                    <div className="billing_left_details">
                                        <h5>Subscription Not Found.</h5>
                                    </div>
                                    <div className="billing_left_details">
                                        <h4>Next billing date</h4>
                                    </div>
                                </>
                            )
                        }

                        </div>
                    </div>

                    <div className="account_setting_table pb-0 mt-4 table-responsive">
                        <Table striped bordered hover >
                            <thead>
                                <tr>
                                    <th scope="col">Date</th>
                                    <th scope="col">Service Period</th>
                                    <th scope="col">Payment Method</th>
                                    <th scope="col">Sub Total</th>
                                    <th scope="col">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                CheckBillingDetials ? (
                                    !BillingLoading ? (
                                        Array.isArray(UserBillingDetials) && UserBillingDetials.map((item,index) => (
                                            <tr key={index}>
                                                <th scope="row">{item.sub_start_date}</th>
                                                <td>
                                                    {item.start_date} - {item.end_date}
                                                    {
                                                        item.sub_total == 0 && item.total == 0 ? <p>(Trial Period)</p>:null
                                                    }
                                                </td>
                                                <td>{item.payment_method}</td>
                                                <td>${item.sub_total}</td>
                                                <td>${item.total}</td>
                                            </tr>   
                                        ))
                                    ) : (
                                        Array.from({ length: 1 }).map((_, index) => (
                                            <div className="subscription_shimmer_card" key={index}></div>
                                        ))
                                    )
                                ) : <tr>
                                        <td style={{textAlign:"center"}}>Billing Details Not Available.</td>
                                    </tr>
                            }
                            </tbody>
                        </Table>
                    </div>
                </Container>
            </div>
        </div>
    )
}
