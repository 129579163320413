import React,{useState,useEffect} from 'react'
import routes from './pages/Routes'
import secureLocalStorage from 'react-secure-storage';

import {useNavigate,Navigate} from 'react-router-dom'
 
export default function Protected(props:any) {
	
	let Cmp = props.Component
	const currentPath = window.location.pathname;
    
	const [isFirstVisit, setIsFirstVisit] = useState<boolean>(true);


	const navigate = useNavigate();
	useEffect(() => {
		
	    if (isFirstVisit) {
			
			if (!secureLocalStorage.getItem('playnow-user')) {
			  navigate(routes.signin);
			} else {

			  if (!secureLocalStorage.getItem('userProfile')) {
				navigate(routes.switchProfile);
			  }
			}
			setIsFirstVisit(false);

		  } else {
			if (!secureLocalStorage.getItem('playnow-user')) {
			  navigate(routes.signin);
			}
		  }
		
	},[isFirstVisit,navigate])

	return (
			<div >
				<Cmp />	
			</div>
	
	)
}