import React, { useEffect, useState } from 'react';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { API_URLS, API_URL_BASE, X_APP_SECRET } from './utils';
import { User, usePlayVideo } from './Helper';
import Modal from 'react-bootstrap/Modal';
import routes from './Routes';


interface CheckoutFormProps {
  data_id: any; // You can replace 'any' with the appropriate type if you know it
  data_type: string;
  updatePaymentStatus: () => void; // Callback function prop
  errorInVideoPayment: () => void; // Callback function prop
  loderShow: () => void; // Callback function prop
  loderHide: () => void; // Callback function prop
}

const ItemPurchase: React.FC<CheckoutFormProps> = ({ data_id = "", data_type = "" ,updatePaymentStatus,errorInVideoPayment,loderShow,loderHide}) => {
  const stripe = useStripe();
  const elements = useElements();
  var user = User();
  const token = user?.data.token;
  const [successMessage, setsuccessMessage] = useState("");
  const [errorMessage, seterrorMessage] = useState("");
  
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const navigate = useNavigate();
  const [showErrorModal, setshowErrorModal] = useState(false);
  const [showModal, setshowModal] = useState(false);
  const handleClose = () =>{
    updatePaymentStatus();
    setshowModal(false);
  } 
  const handleCloseError = () =>{
    errorInVideoPayment();
    setshowErrorModal(false);
  } 
  

  const handleShowModal = () => {
    setshowModal(true);
  };

  const handleShowErrorModal = () => {
    setshowErrorModal(true);
  };

  const PlayVideo = (data_id: number, watching_time: string = "") => {
    const data_type: string = "movie";
    navigate(routes.VideoWatch, { state: { data_id, data_type, watching_time } });
 };

  const[paymentData,setpaymentData] = useState();

  const[transactionId,settransactionId] = useState("");

  const makePayment = async (event: React.FormEvent<HTMLFormElement>) => {

    event.preventDefault();

    if (!stripe || !elements) {
        return;
    }
    const checkCardElement = elements.getElement(CardElement);

    if (!checkCardElement) {
        alert("1")
        console.warn('Card element not found');
        return;
    }

    
    const { error: cardError } = await stripe.createPaymentMethod({
        type: 'card',
        card: checkCardElement,
    });

    if (cardError) {

        // setIsButtonDisabled(false);
        // seterrorMessage(cardError.message  || "")
        // handleShowErrorModal()
        console.warn(cardError.message)
        return;
    }


    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
        
        console.warn('Card element not found');
        return;
    }

    setIsButtonDisabled(true);
    loderShow()

    const paymentRequest = {
        data_id: data_id, // Use profileId here
        data_type: data_type,
    };
    const paymentRequestBody = JSON.stringify(paymentRequest);

    try {
        const result = await fetch(`${API_URL_BASE}${API_URLS.ITEM_PURCHASE}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
                Authorization: "Bearer " + token,
            },
            body: paymentRequestBody,
        });

        const resp = await result.json();

        if (resp.response === true) {
            
            setpaymentData(resp.data);

            const cardElement = elements.getElement(CardElement);

            if (!cardElement) {
                
                console.warn('Card element not found');
                return;
            }

            const { paymentMethod, error } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });

            if (error) {
                console.warn(error);
                return;
            }

            const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
                resp.data.client_secret,
                {
                    payment_method: {
                        card: cardElement,
                        billing_details: {},
                    },
                }
            );

            if (confirmError) {
                setIsButtonDisabled(false);
                seterrorMessage(confirmError.message  || "")
                handleShowErrorModal()
                loderHide()
                return;
            }

            if(paymentIntent.status == 'succeeded'){
                settransactionId(resp.data.transaction_id)
            }else{
                console.warn('PaymentIntetnt Error.');
                loderHide()
            }
        } else {

            handleShowErrorModal()
            seterrorMessage(resp.message)
            loderHide()

        }
    } catch (error) {
        console.warn('Error during payment processing:', error);
    }
  };
  
useEffect(() => {
  
  if (transactionId) {

      const successRequest = {
        transaction_id: transactionId, // Use profileId here
      };

      const successRequestBody = JSON.stringify(successRequest);

      fetch(`${API_URL_BASE}${API_URLS.ITEM_PAYMENT_SUCCESS}`, {
          method: "POST",
          headers: {
              Accept: "application/json",
              "Content-type": "application/json",
              'X-APP-SECRET': X_APP_SECRET,
              Authorization: "Bearer " + token,
          },
          body: successRequestBody,
      }).then((result) => {

          result.json().then((resp) => {

              if (resp.response == true) {
                settransactionId("")
                setIsButtonDisabled(false);
                setsuccessMessage(resp.message)
                handleShowModal()
                loderHide()
              }else{
                
                seterrorMessage(resp.message)
                handleShowErrorModal()
                loderHide()
              }
          })
      })
  }

}, [transactionId]);

  return (
    <div>
      
      <form onSubmit={makePayment} className='stripe_form'>
        <h2>Enter card details</h2>
        <CardElement className='card-element' />
         <div className='card_instructions'>
         <h3>Instructions</h3>
            <ul>
                <li>All major debit and credit cards accepted.</li>
                <li>We don’t store any of your card information</li>
                <li>We handles the card information and transaction with highest level of security.</li>
            </ul>
         </div>
        <button type="submit" className='pay_btn' disabled={isButtonDisabled || !stripe || !elements}>
          Pay
        </button>
        
        {/* <button type="submit" className='pay_btn' onClick={handleShowModal} >Show Model</button> */}
      </form>

        <Modal className='movie_name_modal' show={showModal} onHide={handleClose}  centered backdrop="static" keyboard={false}>
            <Modal.Body>
                <div className='movie_name_close_modal' onClick={handleClose}>
                    <Link to={"#"}><img src='img/modal_close.png'/></Link>
                </div>
                <div className='movie_name_details'>
                    <div className='movie_name_img'>
                        <img src='img/tv.svg'/>
                    </div>
                    <div className='movine_name_section'>
                        <h3>{successMessage}</h3>
                        {/* <button onClick={() => PlayVideo(data_id)}>Play Video</button> */}
                        <button onClick={() => handleClose()}>Done</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
        <Modal className='movie_name_modal' show={showErrorModal} onHide={handleCloseError}  centered backdrop="static" keyboard={false}>
            <Modal.Body>
                <div className='movie_name_close_modal' onClick={handleCloseError}>
                    <Link to={"#"}><img src='img/modal_close.png'/></Link>
                </div>
                <div className='movie_name_details'>
                    <div className='movie_name_img'>
                        <img src='img/tv.svg'/>
                    </div>
                    <div className='movine_name_section'>
                        <h3 className='stripe_error_msg'>{errorMessage}</h3>
                        {/* <button onClick={() => PlayVideo(data_id)}>Play Video</button> */}
                        <button onClick={() => handleCloseError()}>Payment Failed!</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    </div>
  );
};

export default ItemPurchase;
