import React, { useState } from 'react'
import { API_URL_BASE, X_APP_SECRET, headers } from './../utils'
import { API_URLS } from './../utils'
import { useNavigate, Navigate, Link } from 'react-router-dom'
import { IStateSignup } from '../Model/Interface'
import routes from '../Routes'
import secureLocalStorage from 'react-secure-storage'

export default function ChangePassword() {



    const navigate = useNavigate();
    const [errmsg, setErrormsg] = useState("");

    const [state, setState] = useState<{ user: { email: string, password: string, confirm_password: string } }>({
        user: { email: '', password: '', confirm_password: '' },
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setState({
            user: {
                ...state.user,
                [event.target.name]: event.target.value,
            },
        });
    };

    const UpdatePassword = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        let email = state.user.email;
        let password = state.user.password;
        let confirm_password = state.user.confirm_password;

        let data = { email, password, confirm_password }

        fetch(`${API_URL_BASE}${API_URLS.CHANGE_PASSWORD}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
              },
            body: JSON.stringify(data)
        }).then((result) => {
            result.json().then((resp) => {

                if (resp.response == false) {
                    setErrormsg(resp.message);
                } else {
                    secureLocalStorage.removeItem('playnow-user');
                    secureLocalStorage.removeItem('userProfile');
                    secureLocalStorage.removeItem('user-email');
                    navigate(routes.signin)
                }
            })
        })
    }
    return (
        <>
            <div className="login_bg">
                <div className="login_content">
                    <div className="login_logo">
                        <Link to={"/"} className="logo"><img src="/img/play_now_logo.png"></img></Link>
                    </div>
                    <form onSubmit={UpdatePassword}>
                        <div className="login_box">
                            <div className="login_box_title">
                                <h3>New Password</h3>
                            </div>
                            <div className="login_box_content">
                                <div className="input_box">
                                    <span style={{ color: "red" }} >{errmsg}</span>
                                    <input type="email" name="email" value={state.user.email} onChange={handleChange} className="input_fild" placeholder="Email"></input>
                                    <input type="password" name="password" value={state.user.password} onChange={handleChange} className="input_fild" placeholder="Password"></input>
                                    <input type="password" name="confirm_password" value={state.user.confirm_password} onChange={handleChange} className="input_fild" placeholder="Confirm Password"></input>
                                    <input type="submit" className="submit_btn" value="Continue"></input>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
