import React, { useState, useEffect } from 'react'
import { API_URL_BASE, X_APP_SECRET, headers } from './../utils'
import { API_URLS } from './../utils'
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, Navigate, Link, useLocation } from 'react-router-dom'
import { IStateSignup } from '../Model/Interface'
import routes from '../Routes'
import secureLocalStorage from 'react-secure-storage';

export default function Otp() {
    const navigate = useNavigate();
    const location = useLocation();
    const { Preemail } = location.state || {};

    useEffect(() => {
        if (secureLocalStorage.getItem("playnow-user")) {
            navigate(routes.home)
        }
    })
    function showSuccessNotification(message: any) {
        toast.success(message, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    }
    const [errmsg, setErrormsg] = useState("");

    const [state, setState] = useState<{ user: { email: string, activation_key: string } }>({
        user: { email: Preemail && Preemail?Preemail:"", activation_key: '' },
    });
    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setState({
          user: {
            ...state.user,
            [event.target.name]: event.target.value,
          },
        });
      };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const inputValue = event.target.value;
        const numericValue = inputValue.replace(/\D/g, ''); // Remove non-numeric characters
        const truncatedValue = numericValue.slice(0, 6); // Restrict to six digits

        setState({
            user: {
                ...state.user,
                [event.target.name]: truncatedValue,
            },
        });
    };


    const VerifyEmail = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        let email = state.user.email;
        let activation_key = state.user.activation_key;

        let data = { email, activation_key }

        fetch(`${API_URL_BASE}${API_URLS.FORGOT_PASSWORD_OTP_VERIFY}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
              },
            body: JSON.stringify(data)
        }).then((result) => {
            result.json().then((resp) => {

                if (resp.response == false) {
                    setErrormsg(resp.message);
                } else {
                    navigate(routes.changePassword)
                }
            })
        })
    }
    const [isButtonDisabled, setButtonDisabled] = useState(true);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            if (seconds === 0) {
                if (minutes === 0) {
                    setButtonDisabled(false)
                    clearInterval(interval);
                } else {
                    setSeconds(59);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds]);

    const ResendCode = () => {

        setSeconds(60);
        setButtonDisabled(true);

        const userJSON = secureLocalStorage.getItem("user-email") as string;
        const email = userJSON ? JSON.parse(userJSON) : null;
        let data = { email }
        fetch(`${API_URL_BASE}${API_URLS.FORGOT_PASSWORD_OTP_SENT}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
              },
            body: JSON.stringify(data)
        }).then((result) => {
            result.json().then((resp) => {

                if (resp.response == false) {
                    setErrormsg(resp.message);
                }
            })
        })
    }
    return (
        <>
            <div className="login_bg">
                <div className="login_content">
                    <div className="login_logo">
                        <Link to={"/"} className="logo"><img src="/img/play_now_logo.png"></img></Link>
                    </div>
                    <form onSubmit={VerifyEmail}>
                        <div className="login_box">
                            <div className="login_box_title">
                                <h3>Verify OTP</h3>
                            </div>
                            <div className="login_box_content">
                                <div className="input_box">
                                    <span style={{ color: "red" }} >{errmsg}</span>
                                    <input type="email" name="email" value={state.user.email} onChange={handleChangeEmail} className="input_fild" placeholder="Email"></input>
                                    <input type="text" name="activation_key" value={state.user.activation_key} onChange={handleChange} className="input_fild" placeholder="Enter OTP"></input>
                                    {seconds > 0 || minutes > 0 ? (
                                        <div className='remain_text'>
                                            <span className="resend_code_txt p-0">
                                                Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                                                {seconds < 10 ? `0${seconds}` : seconds}
                                            </span>
                                        </div>
                                    ) : (
                                        <div className='resend_btn'>
                                            <span className="resend_code_txt p-0">Didn't receive code?</span>
                                            <button className="resend__action_btn" onClick={ResendCode} disabled={isButtonDisabled}>
                                                Resend
                                            </button>

                                        </div>
                                    )}
                                    <input type="submit" className="submit_btn" value="Continue"></input>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
