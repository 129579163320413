import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './style.css';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';  
import './Owl.css';

import { BrowserRouter } from 'react-router-dom';

const handlewebhvital =(vitals: any) =>{

};
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals(handlewebhvital);
