import React, { useState } from 'react'
import Header from './Layout/Header'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'


  
export default function Payment() {
    const [checked, setChecked] = useState(false);

    const handleChange = () => {
        setChecked(!checked);
    };
  return (
    <div>
        <Header/>
        <div className="account_setting">
            <Container fluid className='cust_container'>
                <div className="account_setting_title">
                    <h3>Payment</h3>
                </div>
                <div className="payment_section">
                    <div className="payment_left_img">
                        <img src="img/payment.png" alt=""></img>
                    </div>
                    <div className="payment_right_section">
                        <form>
                            <div className="row">
                                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                                    <div className="input_box">
                                        <div data-input-focus="true">
                                            <label htmlFor="">Name</label>
                                            <input type="text" className='payment_box' required/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                                    <div className="input_box">
                                        <div data-input-focus="true">
                                            <label htmlFor="" >Email</label>
                                            <input type="text" className='payment_box'  required/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                                    <div className="input_box">
                                        <div data-input-focus="true">
                                            <label htmlFor="">Card Number</label>
                                            <input type="text" className='payment_box'  required/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                                    <div className="input_box">
                                        <div data-input-focus="true">
                                            <label htmlFor="">Expiry Date</label>
                                            <input type="text" className='payment_box'  required/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                                    <div className="input_box">
                                        <div data-input-focus="true">
                                            <label htmlFor="">CVV</label>
                                            <input type="text" className='payment_box'  required/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                                    <div className="input_box">
                                        <div data-input-focus="true">
                                            <label htmlFor="">Premium Plan</label>
                                            <input type="text" className='payment_box'  required/>
                                            <Link to={""} className="change_plan">Change Plan</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                                    <div className="input_box">
                                        <label className="container_check_box mb-0">I agree
                                            <input type="checkbox" checked={checked} onChange={handleChange}></input>
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                                    <div className="input_box mt-3">
                                        <p className="payment_text">
                                            By checking the checkbox below, you agree to our Terms of Use, Privacy Statement, and that you are over 18. You may cancel at any time in your free trial and will not be charged. Playnow will automatically continue your membership at the end of your free trial and charge the membership fee to your payment method on a monthly basis until you cancel.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                                    <input type="submit" value="Pay Now" className="pay_now"/>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Container>
        </div>
    </div>
  )
}
